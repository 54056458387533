<template>
  <CSelect :options="options" :value.sync="selectedOption" @change="setValue" />
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "FilterMonth",
  data() {
    return {
      options: null,
      lang: null,
      messages: null,
      selectedOption: null,
    };
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveFilterMonthValue", {
        value: this.selectedOption,
      });
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    const queryUrl = "sys/filter/months";
    Api.sendGet(queryUrl).then((response) => {
      const arrayResult = [];
      response.data.data.forEach((element) => {
        let item = {
          value: element,
          label: this.messages.filters.months[element],
        };
        arrayResult.push(item);
      });
      this.options = arrayResult;
      this.selectedOption = new Date().getMonth() + 1;
      this.setValue();
    });
  },
};
</script>
